import React, { Fragment } from 'react';
import styled from 'styled-components';
import { colors } from '../consts/style';
import { Link } from 'gatsby';

const Wrapper = styled.nav`
  width: 100%;
  margin: 0 0 76px;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 190px;

  @media (max-width: 1023px) {
    padding: 0;
  }

  &.first-page {
    padding: 0;
    max-width: 440px;
    margin: 0 auto 76px;
  }

  ul {
    width: calc(100% - 60px);
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    font-size: 14px;
    color: ${colors.black2};
    line-height: 32px;
    min-width: 32px;
    text-align: center;
  }

  a {
    display: flex;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: ${colors.black2};

    &:hover {
      background: none;
      color: ${colors.black2};
      text-decoration: none;
    }

    &.current {
      background: ${colors.black2};
      color: ${colors.white};
      text-decoration: none;
    }

    &.pagination-nav {
      width: auto;
      border-radius: 0;
      margin: 0;
    }
  }
`;

const Pagination = ({ pageContext, slug, firstPage }) => {
  const { currentPage, numPages } = pageContext;

  const min = 1;
  let length = 4;
  if (length > numPages) length = numPages;

  let start = currentPage - Math.floor(length / 2);
  start = Math.max(start, min);
  start = Math.min(start, min + numPages - length);

  return (
    <Wrapper className={firstPage ? 'first-page' : ''}>
      <Link className="pagination-nav" to={slug === '/' ? '/' : `/${slug}/`}>
        First
      </Link>
      <ul>
        {numPages > 4 ? (
          <Fragment>
            {currentPage + 1 === numPages || currentPage === numPages ? (
              <li>
                <Link to={slug === '/' ? '/' : `/${slug}/`}>1</Link>
              </li>
            ) : null}

            {!(currentPage + 2 <= numPages || length + 1 === numPages) ? (
              <li>...</li>
            ) : null}
          </Fragment>
        ) : null}

        {Array.from({ length: length }, (_, i) => (
          <li key={`pagination-number${start + i}`}>
            <Link
              className={start + i === currentPage ? 'current' : ''}
              to={
                start + i === 1
                  ? `${slug === '/' ? '/' : `/${slug}/`}`
                  : `${slug === '/' ? '/' : `/${slug}/`}page/${start + i}/`
              }
            >
              {start + i}
            </Link>
          </li>
        ))}

        {numPages > 4 ? (
          <Fragment>
            {!(currentPage + 2 >= numPages || length + 1 === numPages) ? (
              <li>...</li>
            ) : null}

            {!(currentPage + 1 === numPages || currentPage === numPages) ? (
              <li>
                <Link
                  to={`${slug === '/' ? '/' : `/${slug}/`}page/${numPages}/`}
                >
                  {numPages}
                </Link>
              </li>
            ) : null}
          </Fragment>
        ) : null}
      </ul>
      <Link
        className="pagination-nav"
        to={`${slug === '/' ? '/' : `/${slug}/`}page/${numPages}/`}
      >
        Last
      </Link>
    </Wrapper>
  );
};

export default Pagination;
